<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="email"
              label="Email"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="name"
              label="Name"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="phone"
              label="Phone"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="website"
              label="website"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="address"
              label="Address"
              type="text"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="state"
              label="State"
              type="text"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="city"
              label="City"
              type="text"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="zip"
              label="Zip"
              type="text"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="country"
              label="Country"
              type="text"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="service"
              label="Service"
              type="text"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click.prevent="createClient"
            >
              Save
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="cancel"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from '@axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      email: '',
      name: '',
      phone: '',
      website: '',
      address: '',
      state: '',
      city: '',
      service: '',
      zip: '',
      country: '',
    }
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  methods: {
    createClient() {
      axios
        .post('/account/create', {
          email: this.email,
          name: this.name,
          company: this.name,
          phone: this.phone,
          website: this.website,
          address: this.address,
          address2: this.address,
          state: this.state,
          city: this.city,
          service: this.service,
          zip: this.zip,
          country: this.country,
        })
        .then(response => {
          console.log(response.data)
          if (response.data.error) {
            this.$alertify.error(response.data.error)
          } else {
            this.$alertify.success('Client created')
            this.$router.push('/client')
          }
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to update!')
          console.log('error :>> ', error.response)
          this.$alertify.error('unable to update')
        })
    },
    cancel() {
      this.$router.push('/client')
    },
  },
  mounted() {
    if (this.hasUserRole('Super Admin') || this.hasUserRole('Client Owner')) {
    } else {
      this.loading = false
      this.$router.push({ name: 'misc-not-authorized' })
    }
  },
}
</script>
